import "./Video.css";

import VideoSource from "../assets/010.mp4";

export const VideoBox = (
  <>
    <div className="anchor" id="home"></div>
    <video playsInline autoPlay muted loop id="video">
      <source src={VideoSource} type="video/mp4" />
    </video>
  </>
);
