import React from "react";
import { VideoBox } from "../components/VideoBox";
import { Navigation } from "../components/Navigation";
import Footer from "../components/Footer";
import { HashLink as Link } from "react-router-hash-link";
import "./Home.css"; // Import your Home-specific CSS

const Home = () => {
  return (
    <div className="home-page">
      <header className="App-header">
        {Navigation}
        {VideoBox}

        <div id="wraptext">
          <div className="content Box1">
            <h2>We provide expert 3D modeling and 2D drafting services.</h2>
            <p style={{ color: "rgb(180, 180, 180)" }}>
              We're not your usual service provider - you're here because you're
              looking for something better, right? At ENEXSUB, we don't just
              deliver standard solutions. We provide precision-driven 3D
              modeling and 2D drafting services, customized to your needs.
            </p>
          </div>
          <div className="content Box1">
            <p style={{ color: "rgb(180, 180, 180)" }}>
              What sets us apart? Our 3D models are designed to be easy to
              manufacture, thanks to our real-world experience. And our 2D
              drawings are clear, accurate, and ready for production.
            </p>
          </div>

          <div className="center-container">
            <h2>Check our services</h2>
            <Link to={"/services"}>
              <button id="contact-button">Go to services</button>
            </Link>
          </div>

          {/* Imaginary box (spacer) below the button */}
          <div className="spacer"></div>
        </div>
      </header>

    
    </div>
  );
};

export default Home;