import { Route, Routes } from 'react-router-dom';
import { Navigation } from './components/Navigation'; // Ensure path is correct
import Footer from './components/Footer'; // Import the Footer component
import './App.css';
import Home from './pages/Home'; // Ensure path and import are correct
import { Contact } from './pages/Contact'; // Ensure path and named import
import { Privacy } from './pages/Privacy'; // Ensure path and named import
import Services from './pages/Services'; // Ensure path and import are correct

function App() {
  return (
    <div className="App">
      <Navigation /> {/* Include the Navigation component */}
      
      {/* Render the routes for different pages */}
      <Routes>
        <Route path="/" element={<Home />} /> {/* Home Page */}
        <Route path="/contact" element={<Contact />} /> {/* Contact Page */}
        <Route path="/privacy" element={<Privacy />} /> {/* Privacy Page */}
        <Route path="/services" element={<Services />} /> {/* Services Page */}
      </Routes>
      
      <Footer /> {/* Footer will be displayed on every page */}
    </div>
  );
}

export default App;