import React, { useState } from "react";
import "./Navigation.css";
import logoSource from "../assets/Logo-T.svg";
import { HashLink as Link } from "react-router-hash-link";

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div id="navbar">
      <Link to="/#home" smooth>
        <img id="logo" src={logoSource} alt="Logo v01" />
      </Link>
      <div
        className={`burger ${isOpen ? 'active' : ''}`}
        onClick={toggleMenu}
        title={isOpen ? 'Close' : 'Open'}
      >
        <span></span>
      </div>
      <ul id="navlinks" className={isOpen ? "open" : ""}>
        <li>
          <Link to="/#home" smooth onClick={() => setIsOpen(false)}>Home</Link>
        </li>
        <li>
          <Link to="/services" onClick={() => setIsOpen(false)}>Services</Link>
        </li>
        <li>
          <Link to="/contact" onClick={() => setIsOpen(false)}>Contact</Link>
        </li>
      </ul>
    </div>
  );
};